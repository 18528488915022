import * as t from 'io-ts';

import {
  UseDeleteAPIParams,
  useDeleteAPI,
  useGetAPI,
  usePostAPI,
  useQueryClient,
} from 'client/hooks/useAPI';
import useErrorNotification from 'client/hooks/useErrorNotification';

import { Cart, Carts, SaveCart } from 'types/carts';
import { CartId, UserId } from 'types/ids';
import { CalculatedProductInfo } from 'types/products';

const queryKeys = {
  carts: (userId: UserId) => ['carts', userId],
  cartCalculatedInfo: (cartId: UserId) => ['cart', cartId, 'info'],
};

export function useCarts(userId: UserId) {
  return useGetAPI(`/api/carts`, { type: Carts, queryKey: queryKeys.carts(userId) });
}

export const CalculatedCartInfo = t.array(CalculatedProductInfo);
export type CalculatedCartInfo = t.TypeOf<typeof CalculatedCartInfo>;
export function useCartCalculatedInfo(cartId: CartId) {
  return useGetAPI(`/api/carts/${cartId}/price`, {
    type: t.array(CalculatedProductInfo),
    queryKey: queryKeys.cartCalculatedInfo(cartId),
  });
}

interface UseDeleteCartParams {
  userId: UserId;
  cartId: CartId;
  onError?: UseDeleteAPIParams['onError'];
  onSuccess?: () => void;
}
export function useDeleteCart({ userId, cartId, onSuccess, onError }: UseDeleteCartParams) {
  const queryClient = useQueryClient();

  return useDeleteAPI(`/api/carts/${cartId}`, {
    type: t.void,
    onError,
    onSuccess() {
      queryClient.setQueryData<Carts>(
        queryKeys.carts(userId),
        previousData => previousData?.filter(cart => cart.id !== cartId) ?? [],
      );
      onSuccess?.();
    },
  });
}

interface UseSaveCartParams {
  userId: UserId;
  onSuccess?: () => void;
}
export function useSaveCart({ userId, onSuccess }: UseSaveCartParams) {
  const queryClient = useQueryClient();
  const { notifyError } = useErrorNotification();

  return usePostAPI<SaveCart, Cart>('/api/carts', {
    type: Cart,
    onSuccess(data) {
      queryClient.setQueryData<Carts>(queryKeys.carts(userId), (previousData = []) => [
        ...previousData,
        data,
      ]);
      onSuccess?.();
    },
    onError(error) {
      notifyError(error);
    },
  });
}
