export function getHeaderHeight() {
  const defaultHeaderHeight = 118;

  if (typeof window === 'undefined') return defaultHeaderHeight;

  const header = document.querySelector('header');
  return (header && header.offsetHeight) || defaultHeaderHeight;
}

export function getPatternForHtmlInput(string: string) {
  return string.replace(/\./g, '\\.');
}
