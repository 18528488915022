import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import RouterLink from 'next/link';
import { MouseEvent, forwardRef } from 'react';
import { scrollTo } from 'client/utils/scrollTo';
export interface LinkProps extends Omit<MuiLinkProps, 'component'> {
  href: string;
  disabled?: boolean;
}
const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link({
  href,
  target = '_self',
  disabled,
  children,
  ...props
}, ref) {
  // http://
  // https://
  // tel:
  // mailto:
  // ...:
  // /foo
  // foo
  // /foo?bar=true
  // /foo?bar=true#baz
  // ?bar=true
  // #baz

  const isExternalLink = href.indexOf(':') > -1;
  const isFragmentLink = /^#/.test(href);
  const isOpeningInSameWindow = target === '_self';
  if (disabled || isExternalLink || isFragmentLink || !isOpeningInSameWindow) {
    return <MuiLink {...props} ref={ref} component={disabled ? 'span' : 'a'} href={href} target={target} onClick={(event: MouseEvent) => {
      if (isFragmentLink) {
        event.preventDefault();
        scrollTo(href);
      }
    }}>
        {children}
      </MuiLink>;
  } else {
    return <MuiLink {...props} component={RouterLink} href={href} ref={ref}>
        {children}
      </MuiLink>;
  }
});
export default Link;