import React, { ReactNode } from 'react';
import Divider from 'client/elements/Divider';
import Grid from 'client/elements/Grid';
import Typography, { TypographyProps } from 'client/elements/Typography';
import styles from './PageContainer.module.css';
interface PageContainerProps {
  title?: string;
  titleProps?: TypographyProps;
  endAdornment?: React.ReactNode;
  children: ReactNode;
}
const PageContainer = ({
  title = '',
  titleProps = {},
  endAdornment = null,
  children
}: PageContainerProps) => {
  return <div className={styles.container} data-sentry-component="PageContainer" data-sentry-source-file="PageContainer.tsx">
      {title && <>
          <Grid container={true} spacing={2} paddingBottom={1} alignItems="flex-end" justifyContent="space-between" className={styles.headline}>
            <Grid item={true}>
              <Typography variant="h1" color="primary" className={styles.title} {...titleProps as any}>
                {title}
              </Typography>
            </Grid>
            {endAdornment && <Grid item={true}>
                <Typography component="div" className={styles.title}>
                  {endAdornment}
                </Typography>
              </Grid>}
          </Grid>

          <Divider className={styles.divider} />
        </>}
      <div>{children}</div>
    </div>;
};
export default PageContainer;