import * as t from 'io-ts';

import { Image, TranslatedText } from './globals';

export const homePageId = 1;

export const BlogHomePage = t.type({
  metaDescription: TranslatedText,
  title: TranslatedText,
  content: TranslatedText,
});
export type BlogHomePage = t.TypeOf<typeof BlogHomePage>;

export const CreateBlogPost = t.type({
  metaDescription: TranslatedText,
  title: TranslatedText,
  content: TranslatedText,
  coverImage: t.union([Image, t.null]),
  published: t.boolean,
});
export type CreateBlogPost = t.TypeOf<typeof CreateBlogPost>;

export const BlogPost = t.intersection([CreateBlogPost, t.type({ id: t.number })]);
export type BlogPost = t.TypeOf<typeof BlogPost>;

export const BlogPosts = t.array(BlogPost);
export type BlogPosts = t.TypeOf<typeof BlogPosts>;

export const CreateBlogCategory = t.type({
  metaDescription: TranslatedText,
  name: TranslatedText,
  description: TranslatedText,
  posts: t.array(t.number),
  parentId: t.union([t.number, t.null]),
});
export type CreateBlogCategory = t.TypeOf<typeof CreateBlogCategory>;

export const BlogCategory = t.intersection([CreateBlogCategory, t.type({ id: t.number })]);
export type BlogCategory = t.TypeOf<typeof BlogCategory>;

export const BlogCategories = t.array(BlogCategory);
export type BlogCategories = t.TypeOf<typeof BlogCategories>;

export interface BlogCategoryTree {
  text: string;
  href: string;
  children: BlogCategoryTree[];
}
export const BlogCategoryTree: t.Type<BlogCategoryTree> = t.recursion('BlogCategoryTree', () =>
  t.type({ text: t.string, href: t.string, children: t.array(BlogCategoryTree) }),
);
export const BlogCategoriesTree = t.array(BlogCategoryTree);
export type BlogCategoriesTree = t.TypeOf<typeof BlogCategoriesTree>;
