import MuiDivider, { DividerProps as MuiDividerProps } from '@mui/material/Divider';
import classNames from 'clsx';
import styles from './Divider.module.css';
interface Props extends Omit<MuiDividerProps, 'classes'> {
  margin?: 'none' | 'dense' | 'normal';
}
type MarginClassName = 'noneMargin' | 'denseMargin' | 'normalMargin';
const Divider = ({
  className,
  margin = 'none',
  ...props
}: Props) => {
  const marginClassName = `${margin}Margin` as MarginClassName;
  const internalClassNames = [className, styles[marginClassName]];
  return <MuiDivider {...props} className={classNames(internalClassNames)} data-sentry-element="MuiDivider" data-sentry-component="Divider" data-sentry-source-file="Divider.tsx" />;
};
export default Divider;