import styles from './Logo.module.css';
interface Props {
  className?: string;
}
const Logo = ({
  className
}: Props) => {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221.83 247.11" data-sentry-element="svg" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <defs data-sentry-element="defs" data-sentry-source-file="Logo.tsx">
        <linearGradient id="linear-gradient" x1="135.35" y1="185.86" x2="318.58" y2="369.09" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Logo.tsx">
          <stop offset="0.05" stopColor="#4f4f4f" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.31" stopColor="#d6d6d6" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.62" stopColor="#4f4f4f" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="1" stopColor="#b8b8b8" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="73.85" y1="28.54" x2="257.08" y2="211.77" xlinkHref="#linear-gradient" data-sentry-element="linearGradient" data-sentry-source-file="Logo.tsx" />
        <linearGradient id="linear-gradient-3" x1="130.32" y1="239.65" x2="300.1" y2="239.65" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Logo.tsx">
          <stop offset="0" stopColor="#242424" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.02" stopColor="#323232" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.11" stopColor="#6f6f6f" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.2" stopColor="#a3a3a3" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.28" stopColor="#cbcbcb" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.36" stopColor="#e7e7e7" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.42" stopColor="#f9f9f9" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
          <stop offset="0.47" stopColor="#fff" data-sentry-element="stop" data-sentry-source-file="Logo.tsx" />
        </linearGradient>
        <linearGradient id="linear-gradient-4" x1="202.4" y1="237.83" x2="102.53" y2="137.97" xlinkHref="#linear-gradient" data-sentry-element="linearGradient" data-sentry-source-file="Logo.tsx" />
      </defs>
      <title>Logo Gabari Prod</title>
      <g className={styles.cls1} data-sentry-element="g" data-sentry-source-file="Logo.tsx">
        <g id="Calque_1" data-name="Calque 1" data-sentry-element="g" data-sentry-source-file="Logo.tsx">
          <path className={styles.cls2} d="M177.53,143.68l.21,118.8s-46.62-.5-47.41,33.44c-1,43.58,46.18,39.6,46.18,39.6H300.1L226.48,143.68Z" transform="translate(-130.32 -88.5)" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
          <polygon className={styles.cls3} points="221.83 55.18 115.67 55.18 170.98 247.02 221.83 247.02 221.83 55.18" data-sentry-element="polygon" data-sentry-source-file="Logo.tsx" />
          <path className={styles.cls4} d="M177.53,143.68l.21,118.8s-46.62-.5-47.41,33.44c-1,43.58,46.18,39.6,46.18,39.6H300.1L226.48,143.68Z" transform="translate(-130.32 -88.5)" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
          <path className={styles.cls5} d="M130.86,116.8v174c5.14-28.73,46.88-28.3,46.88-28.3l-.2-174C174.59,88.51,135.81,89.17,130.86,116.8Z" transform="translate(-130.32 -88.5)" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        </g>
      </g>
    </svg>;
};
export default Logo;