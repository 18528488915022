import * as t from 'io-ts';

import {
  useDeleteAPI,
  useGetAPI,
  usePostAPI,
  usePutAPI,
  useQueryClient,
} from 'client/hooks/useAPI';

import {
  BlogCategories,
  BlogCategoriesTree,
  BlogCategory,
  BlogHomePage,
  BlogPost,
  BlogPosts,
  CreateBlogCategory,
  CreateBlogPost,
} from 'types/blog';

const queryKeys = {
  blogCategoriesTree: ['blog-categories-tree'],
  adminBlogCategories: ['admin-blog-categories'],
  adminBlogCategory: (blogCategoryId: number) => ['admin-blog-categories', blogCategoryId],
  adminBlogHomePage: ['admin-blog-home-page'],
  adminBlogPosts: ['admin-blog-posts'],
  adminBlogPost: (blogPostId: number) => ['admin-blog-posts', blogPostId],
};

export function useGetBlogCategoriesTree() {
  return useGetAPI(`/api/blog-categories-tree`, {
    type: BlogCategoriesTree,
    queryKey: queryKeys.adminBlogCategories,
  });
}

export function useAdminBlogCategories() {
  return useGetAPI(`/api/admin/blog-categories`, {
    type: BlogCategories,
    queryKey: queryKeys.adminBlogCategories,
  });
}

export function useAdminBlogCategory(blogCategoryId: number) {
  return useGetAPI(`/api/admin/blog-categories/${blogCategoryId}`, {
    type: BlogCategory,
    queryKey: queryKeys.adminBlogCategory(blogCategoryId),
  });
}

export function useAdminCreateBlogCategory() {
  const queryClient = useQueryClient();
  return usePostAPI<CreateBlogCategory, BlogCategory>(`/api/admin/blog-categories`, {
    type: BlogCategory,
    onSuccess: newBlogCategory => {
      queryClient.setQueryData<BlogCategory>(
        queryKeys.adminBlogCategory(newBlogCategory.id),
        newBlogCategory,
      );

      queryClient.setQueryData<BlogCategories>(
        queryKeys.adminBlogCategories,
        (previousBlogCategories = []) => [...previousBlogCategories, newBlogCategory],
      );
    },
  });
}

export function useAdminUpdateBlogCategory(blogCategoryId: number) {
  const queryClient = useQueryClient();
  return usePutAPI<BlogCategory, BlogCategory>(`/api/admin/blog-categories/${blogCategoryId}`, {
    type: BlogCategory,
    onSuccess: updatedBlogCategory => {
      queryClient.setQueryData<BlogCategory>(
        queryKeys.adminBlogCategory(updatedBlogCategory.id),
        updatedBlogCategory,
      );

      queryClient.setQueryData<BlogCategories>(
        queryKeys.adminBlogCategories,
        (previousBlogCategories = []) =>
          previousBlogCategories.map(previousBlogCategory =>
            previousBlogCategory.id === blogCategoryId ? updatedBlogCategory : previousBlogCategory,
          ),
      );
    },
  });
}

export function useAdminDeleteBlogCategory(blogCategoryId: number) {
  const queryClient = useQueryClient();
  return useDeleteAPI(`/api/admin/blog-categories/${blogCategoryId}`, {
    type: t.void,
    onSuccess: () => {
      queryClient.setQueryData<BlogCategories>(
        queryKeys.adminBlogCategories,
        blogCategories =>
          blogCategories?.filter(blogCategory => blogCategory.id !== blogCategoryId) ?? [],
      );

      queryClient.invalidateQueries(queryKeys.adminBlogCategory(blogCategoryId));
    },
  });
}

export function useAdminBlogHomePage() {
  return useGetAPI(`/api/admin/blog-home-page`, {
    type: BlogHomePage,
    queryKey: queryKeys.adminBlogHomePage,
  });
}

export function useAdminUpdateBlogHomePage() {
  const queryClient = useQueryClient();
  return usePutAPI<BlogHomePage, BlogHomePage>(`/api/admin/blog-home-page`, {
    type: BlogHomePage,
    onSuccess: updatedBlogHomePage => {
      queryClient.setQueryData<BlogHomePage>(queryKeys.adminBlogHomePage, updatedBlogHomePage);
    },
  });
}

export function useAdminBlogPosts() {
  return useGetAPI(`/api/admin/blog-posts`, {
    type: BlogPosts,
    queryKey: queryKeys.adminBlogPosts,
  });
}

export function useAdminBlogPost(blogPostId: number) {
  return useGetAPI(`/api/admin/blog-posts/${blogPostId}`, {
    type: BlogPost,
    queryKey: queryKeys.adminBlogPost(blogPostId),
  });
}

export function useAdminCreateBlogPost() {
  const queryClient = useQueryClient();
  return usePostAPI<CreateBlogPost, BlogPost>(`/api/admin/blog-posts`, {
    type: BlogPost,
    onSuccess: newBlogPost => {
      queryClient.setQueryData<BlogPost>(queryKeys.adminBlogPost(newBlogPost.id), newBlogPost);

      queryClient.setQueryData<BlogPosts>(queryKeys.adminBlogPosts, (previousBlogPosts = []) => [
        ...previousBlogPosts,
        newBlogPost,
      ]);
    },
  });
}

export function useAdminUpdateBlogPost(blogPostId: number) {
  const queryClient = useQueryClient();
  return usePutAPI<BlogPost, BlogPost>(`/api/admin/blog-posts/${blogPostId}`, {
    type: BlogPost,
    onSuccess: updatedBlogPost => {
      queryClient.setQueryData<BlogPost>(
        queryKeys.adminBlogPost(updatedBlogPost.id),
        updatedBlogPost,
      );

      queryClient.setQueryData<BlogPosts>(queryKeys.adminBlogPosts, (previousBlogPosts = []) =>
        previousBlogPosts.map(previousBlogPost =>
          previousBlogPost.id === blogPostId ? updatedBlogPost : previousBlogPost,
        ),
      );
    },
  });
}

export function useAdminDeleteBlogPost(blogPostId: number) {
  const queryClient = useQueryClient();
  return useDeleteAPI(`/api/admin/blog-posts/${blogPostId}`, {
    type: t.void,
    onSuccess: () => {
      queryClient.setQueryData<BlogPosts>(
        queryKeys.adminBlogPosts,
        blogPosts => blogPosts?.filter(blogPost => blogPost.id !== blogPostId) ?? [],
      );

      queryClient.invalidateQueries(queryKeys.adminBlogPost(blogPostId));
    },
  });
}
