import Slug from 'slug';

import { Locale } from 'client/utils/i18n';

const defaultLocale: Locale = 'fr';

export function getCategorySlug(categoryName: string) {
  return Slug(categoryName, { lower: true });
}

export function getCategoryBaseUrl(id: number) {
  return `/category/${id}`;
}

export function getCategoryUrl(
  data: { id: number; name: { [key: string]: string | undefined } },
  locale?: string,
) {
  const name = data.name[locale || defaultLocale] || data.name[defaultLocale] || '';
  return `${getCategoryBaseUrl(data.id)}/${getCategorySlug(name)}`;
}
