import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import styles from './Drawer.module.css';
interface Props extends Omit<MuiDrawerProps, 'classes'> {}
const Drawer = ({
  children,
  ...props
}: Props) => {
  return <MuiDrawer {...props} classes={{
    root: styles.root
  }} data-sentry-element="MuiDrawer" data-sentry-component="Drawer" data-sentry-source-file="Drawer.tsx">
      {children}
    </MuiDrawer>;
};
export default Drawer;