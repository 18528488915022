import { getHeaderHeight } from './dom';

export function scrollTo(querySelectorOrElement: string | HTMLElement) {
  let element: HTMLElement | null;
  if (typeof querySelectorOrElement === 'string') {
    element = document.querySelector(querySelectorOrElement);
  } else {
    element = querySelectorOrElement;
  }

  if (element) {
    window.scroll({ top: element.offsetTop - getHeaderHeight(), behavior: 'smooth' });
  }
}
