import { useTheme } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import React, { ReactNode } from 'react';
import { ImageObject as ImageObjectSchema, ItemList as ItemListSchema, Organization as OrganizationSchema, Product as ProductSchema, SiteNavigationElement as SiteNavigationElementSchema, WebPage as WebPageSchema } from 'schema-dts';
import AppBar from 'client/components/AppBar';
import Drawer from 'client/components/Drawer';
import Footer from 'client/components/Footer';
import styles from 'client/components/Layout.module.css';
import Promos from 'client/components/Promos';
import { useGetBlogCategoriesTree } from 'client/services/blog';
import { useTranslation } from 'client/utils/i18n';
import { getCategoryUrl } from 'utils/category';
import { getProductUrl } from 'utils/product';
const DynamicLazyCookiePolicy = dynamic(() => import('./CookiePolicy'), {
  ssr: false
});
type Schema = OrganizationSchema | ItemListSchema | ProductSchema | SiteNavigationElementSchema | WebPageSchema | ImageObjectSchema;
const defaultSchemas: Schema[] = [{
  '@type': 'Organization',
  legalName: 'Credence inox',
  url: 'https://www.credence-inox.com',
  logo: 'https://www.credence-inox.com/static/img/logo.png',
  contactPoint: [{
    '@type': 'ContactPoint',
    telephone: '+33(4) 75 48 74 14',
    contactType: 'customer service'
  }],
  sameAs: ['http://www.facebook.com/credence.inox.cuisine', 'https://www.instagram.com/credence_inox.com_', 'http://www.twitter.com/Credenceinox', 'https://plus.google.com/111458417157480312937', 'https://www.pinterest.fr/credenceinox', 'https://www.linkedin.com/company/credence-inox'],
  image: ['https://www.credence-inox.com/static/img/home/slider/1-credence-standard-sm.jpg', 'https://www.credence-inox.com/static/img/home/slider/2-credence-sur-mesure-sm.jpg', 'https://www.credence-inox.com/static/img/home/slider/3-plan-de-travail-sm.jpg', 'https://www.credence-inox.com/static/img/home/slider/4-miroir-sm.jpg', 'https://www.credence-inox.com/static/img/home/slider/5-plan-de-travail-led-sm.jpg'],
  address: {
    '@type': 'PostalAddress',
    streetAddress: '330 Route de MOURS',
    addressLocality: 'PEYRINS',
    postalCode: '26380 ',
    addressCountry: 'FR'
  }
}, {
  '@type': 'SiteNavigationElement',
  name: 'Crédences',
  url: 'https://www.credence-inox.com/category/0/credences'
}, {
  '@type': 'SiteNavigationElement',
  name: 'Plans de travail',
  url: 'https://www.credence-inox.com/category/1/plans-de-travail'
}, {
  '@type': 'SiteNavigationElement',
  name: 'Accessoires',
  url: 'https://www.credence-inox.com/category/2/accessoires'
}, {
  '@type': 'SiteNavigationElement',
  name: 'Conseils',
  url: 'https://www.credence-inox.com/blog/'
}];
export interface LayoutProps {
  title?: string;
  description?: string;
  schemas?: Schema[];
  children: ReactNode;
}
const Layout = ({
  title = 'Crédence inox Plan de travail inox. Particuliers et professionnels',
  description = 'Standard et sur mesure. Qualité, conseil, photos, devis en ligne, site complet. Gamme pour professionnels. Vente en ligne aux particuliers. Qualité prix excellent',
  schemas = defaultSchemas,
  children
}: LayoutProps) => {
  const theme = useTheme();
  const {
    i18n
  } = useTranslation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = React.useCallback(() => setDrawerOpen(state => !state), []);
  const blogCategoriesTreeResult = useGetBlogCategoriesTree();
  const links = React.useMemo(() => [{
    text: {
      en: 'Standard backsplash',
      fr: 'Crédences standard'
    }[i18n.language],
    href: getProductUrl({
      id: 1,
      name: {
        en: 'Standard backsplash',
        fr: 'Crédences standard'
      }
    })
  }, {
    text: {
      en: 'Backsplash',
      fr: 'Crédences sur mesure'
    }[i18n.language],
    href: getCategoryUrl({
      id: 0,
      name: {
        en: 'Backsplash',
        fr: 'Crédences sur mesure'
      }
    })
  }, {
    text: {
      en: 'Workplans',
      fr: 'Plans de travail sur mesure'
    }[i18n.language],
    href: getCategoryUrl({
      id: 1,
      name: {
        en: 'Workplans',
        fr: 'Plans de travail sur mesure'
      }
    })
  }, {
    text: {
      en: 'Accessories',
      fr: 'Accessoires'
    }[i18n.language],
    href: getCategoryUrl({
      id: 2,
      name: {
        en: 'Accessories',
        fr: 'Accessoires'
      }
    })
  }, {
    text: {
      en: 'Advises',
      fr: 'Conseils'
    }[i18n.language],
    href: '/blog',
    children: blogCategoriesTreeResult.data
  }], [i18n.language, blogCategoriesTreeResult.data]);
  return <div className={styles.ieFixContainer} data-sentry-component="Layout" data-sentry-source-file="Layout.tsx">
      <div className={styles.container}>
        <Head data-sentry-element="Head" data-sentry-source-file="Layout.tsx">
          <title>{title}</title>
          <meta name="description" content={description} data-sentry-element="meta" data-sentry-source-file="Layout.tsx" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width" data-sentry-element="meta" data-sentry-source-file="Layout.tsx" />
          <meta name="google-site-verification" content="O5aVTqZ4wxeHgHRGjr6iXSQ5CNnLvXJ4OTyA17gcglQ" data-sentry-element="meta" data-sentry-source-file="Layout.tsx" />
          <style dangerouslySetInnerHTML={{
          __html: `
              @font-face {
                font-family: 'brand';
                font-display: auto;
                font-weight: normal;
                font-style: normal;
                src: url('/fonts/N-Gage.ttf') format('truetype');
              }

              body {
                font-family: font1;
                font-display: auto;
                background: -webkit-linear-gradient(50deg, #b8bac6 0%,#f5f6f6 70%,#dddfe3 80%,#b8bac6 100%) !important;
                background-color: ${theme.palette.background.default} !important;
              }
            `
        }} />
        </Head>
        <Script id="structured-data" type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'http://schema.org',
          '@graph': schemas
        })
      }} data-sentry-element="Script" data-sentry-source-file="Layout.tsx" />
        <DynamicLazyCookiePolicy data-sentry-element="DynamicLazyCookiePolicy" data-sentry-source-file="Layout.tsx" />
        <AppBar links={links} toggleDrawer={toggleDrawer} data-sentry-element="AppBar" data-sentry-source-file="Layout.tsx" />
        <Drawer open={drawerOpen} links={links} toggleDrawer={toggleDrawer} data-sentry-element="Drawer" data-sentry-source-file="Layout.tsx" />
        <Promos data-sentry-element="Promos" data-sentry-source-file="Layout.tsx" />

        <section className={styles.content}>{children}</section>

        <Footer data-sentry-element="Footer" data-sentry-source-file="Layout.tsx" />
      </div>
    </div>;
};
export default Layout;