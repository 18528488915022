import React from 'react';
import Button from 'client/elements/Button';
import Dialog from 'client/elements/Dialog';
import DialogActions from 'client/elements/DialogActions';
import DialogContent from 'client/elements/DialogContent';
import DialogContentText from 'client/elements/DialogContentText';
import DialogTitle from 'client/elements/DialogTitle';
import TextField from 'client/elements/TextField';
import useCart from 'client/hooks/useCart';
import { useSaveCart } from 'client/services/carts';
import { useTranslation } from 'client/utils/i18n';
import { UserId } from 'types/users';
interface SaveCartProps {
  userId: UserId;
  open: boolean;
  onClose: () => void;
  onCartSaved?: () => void;
}
const SaveCart = ({
  userId,
  open,
  onClose,
  onCartSaved
}: SaveCartProps) => {
  const {
    t
  } = useTranslation();
  const {
    cartItems
  } = useCart();
  const [cartName, setCartName] = React.useState('');
  const {
    mutateAsync: saveCart,
    isLoading: isSaving
  } = useSaveCart({
    userId,
    onSuccess() {
      onCartSaved?.();
      onClose();
    }
  });
  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (isSaving) return;
    await saveCart({
      name: cartName,
      items: cartItems.map(({
        productId,
        optionsValues,
        quantity
      }) => ({
        productId,
        optionsValues,
        quantity
      }))
    });
  }
  return <Dialog open={open} onClose={onClose} data-sentry-element="Dialog" data-sentry-component="SaveCart" data-sentry-source-file="SaveCart.tsx">
      <form onSubmit={handleSubmit}>
        <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="SaveCart.tsx">{t('main:save_my_cart')}</DialogTitle>
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="SaveCart.tsx">
          <DialogContentText data-sentry-element="DialogContentText" data-sentry-source-file="SaveCart.tsx">{t('main:saving_cart_description')}</DialogContentText>

          <TextField type="text" name="name" label={t('main:cart_name')} margin="dense" disabled={isSaving} autoFocus={true} fullWidth={true} value={cartName} onChange={event => setCartName(event.target.value)} data-sentry-element="TextField" data-sentry-source-file="SaveCart.tsx" />
        </DialogContent>
        <DialogActions data-sentry-element="DialogActions" data-sentry-source-file="SaveCart.tsx">
          <Button type="button" onClick={onClose} data-sentry-element="Button" data-sentry-source-file="SaveCart.tsx">
            {t('main:cancel')}
          </Button>
          <Button color="secondary" type="submit" loading={isSaving} data-sentry-element="Button" data-sentry-source-file="SaveCart.tsx">
            {t('main:save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>;
};
export default SaveCart;